import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Box, ResponsiveContext } from '../ui';
import Layout from '../layout/primary';

const StyledBox = styled(Box)`
  margin: auto;
  width: 100%;
  max-width: 1440px;
  padding: 20px;
  overflow: hidden;
  @media screen and (min-width: 769px) {
    padding: 90px;
  }
`;

export const query = graphql`
  {
    prismic {
      allSite_maps {
        edges {
          node {
            meta_title
            meta_description
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.allSite_maps.edges[0].node;

  return (
    <Layout title={content.meta_title} description={content.meta_description}>
      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            <StyledBox size={size}>
              <ul>
                <li>
                  <a href="/">Homepage</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                </li>
                <li>
                  <a href="/cityworks">Cityworks&reg;</a>
                </li>
                <li>
                  <a href="/power360">Power360&reg;</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/careers">Careers</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
                <li>
                  <a href="/locations">Locations</a>
                </li>
                <li>
                  <a href="/library">Library</a>
                </li>
                <li>
                  <a href="/terms">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/supplier-registration">Supplier Registration</a>
                </li>
                <li>
                  <a href="/supplier-diversity">Supplier Diversity&reg;</a>
                </li>
                <li>
                  <a href="https://www.powereng.com/PWC/Account/Login?ReturnUrl=%252fpwc">
                    Client Login
                  </a>
                </li>
              </ul>
            </StyledBox>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
